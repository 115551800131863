<template>
  <div class="container">
    <header class="header">
      <h1>When is GTA 6 Out?</h1>
      <h2>{{ releaseDateInfo }}</h2>
    </header>

    <section class="trailer-section">
      <div class="trailer-wrapper">
        <iframe
  :src="youtubeTrailerUrl"
  title="GTA 6 Official Trailer"
  class="trailer"
  frameborder="0"
  allowfullscreen
  @click="handleVideoClick"
></iframe>
      </div>
    </section>

    <section class="media-section">
      <div class="media-grid">
        <img
          v-for="(screenshot, index) in screenshots"
          :key="index"
          :src="screenshot.url"
          :alt="screenshot.alt"
          class="media-item"
          @click="openPopup(screenshot.url)"
        />
      </div>
    </section>

    <!-- Popup for viewing images -->
    <div v-if="popupVisible" class="popup-overlay" @click="closePopup">
      <div class="popup-content">
        <img :src="popupImage" alt="Full View" />
      </div>
    </div>

    <!-- Placeholder for future news section -->
    <section class="news-placeholder">
      <h3>GTA 6 News (Coming Soon)</h3>
      <p>You will be able to add news here manually in the future.</p>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      releaseDateInfo: "Fall 2025.  ",
      youtubeTrailerUrl: "https://www.youtube.com/embed/QdBZY2fkU-0",
      screenshots: [
        { url: "https://images.igdb.com/igdb/image/upload/t_720p/scpsp7.webp", alt: "GTA 6 Screenshot 1" },
        { url: "https://images.igdb.com/igdb/image/upload/t_720p/scpsp6.webp", alt: "GTA 6 Screenshot 2" },
        { url: "https://images.igdb.com/igdb/image/upload/t_720p/scpsp9.webp", alt: "GTA 6 Screenshot 3" },
        { url: "https://images.igdb.com/igdb/image/upload/t_720p/scpsp8.webp", alt: "GTA 6 Screenshot 4" },
        { url: "https://images.igdb.com/igdb/image/upload/t_720p/sct9wv.webp", alt: "GTA 6 Screenshot 5" },
        { url: "https://images.igdb.com/igdb/image/upload/t_720p/ar2nxz.webp", alt: "GTA 6 Screenshot 6" },

      ],
      popupVisible: false,
      popupImage: "",
    };
  },
  methods: {
    openPopup(imageUrl) {
      this.popupImage = imageUrl;
      this.popupVisible = true;
    },
    handleVideoClick() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    if (isMobile) {
      // Open video in a new tab for mobile users
      window.open(this.youtubeTrailerUrl, '_blank');
    }
  },
    closePopup() {
      this.popupVisible = false;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

body {
  background-color: white;
  color: black;
}

.container {
  font-family: "Inter", sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 1px solid;
  border-color: white;
}

h1 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 40px;
}

h2 {
  color: #ffffff;
  font-size: 4em;
}

h3 {
  color: #000;
  font-size: 18px;
}

p {
  font-size: 1.2rem;
  color: #000;
}

.trailer-section {
  margin-bottom: 40px;
}

.trailer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trailer {
  width: 80%;
  max-width: 800px;
  aspect-ratio: 16/9;
  border-radius: 8px;
}

.media-section {
  margin-bottom: 40px;
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.media-item {
  width: 100%;
  height: 180px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.media-item:hover {
  transform: scale(1.05);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  max-width: 90%;
  max-height: 90%;
}

.popup-content img {
  width: 100%;
  height: 75%;
  border-radius: 8px;
}

.news-placeholder {
  display: none;
  margin-top: 40px;
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #f9f9f9;
}

  @media (max-width: 768px) {
.popup-content img {
  height: 30%;
}
  }
</style>

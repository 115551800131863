import { createRouter, createWebHistory } from 'vue-router';
import NewsFeed from '../src/components/NewsFeed.vue';
import { useHead } from '@vueuse/head'; // Import useHead for meta management

const routes = [
  { 
    path: '/', 
    component: NewsFeed, 
    meta: { 
      title: 'When is GTA 6 Out? - GTA 6 Release Date',
      description: 'Find out when GTA 6 is coming out! Stay informed on the GTA 6 release date, latest trailers, screenshots, and more. Your go-to source for everything about GTA 6.'
    } 
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global navigation guard to handle meta tags
router.beforeEach((to, from, next) => {
  const defaultTitle = 'When is GTA 6 Out? - GTA 6 Release Date & Latest New';
  const defaultDescription = 'Find out when GTA 6 is coming out! Stay up-to-date with the latest news, rumors, trailers, and the official release date of GTA 6.';

  // If the route has a meta title and description, set them; otherwise, use defaults
  useHead({
    title: to.meta.title || defaultTitle,
    meta: [
      {
        name: 'description',
        content: to.meta.description || defaultDescription,
      },
    ],
  });

  next();
});

export default router;

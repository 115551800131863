<template>
  <div id="app">
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
};
</script>

<style>
body {
  background-color: black !important;
  color: black !important;
}</style>
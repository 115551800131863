import { createApp } from 'vue';
import App from './App.vue';
import router from '../router';  // Make sure router is imported
import { createHead } from '@vueuse/head'; // Import createHead for Vue 3

// Import Font Awesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faXTwitter, faFacebook, faSteam, faWikipediaW, faReddit, faTwitch, faYoutube, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'; // For default websites

// Add icons to the library
library.add(faXTwitter, faFacebook, faSteam, faWikipediaW, faReddit, faGlobe, faTwitch, faYoutube, faDiscord);

const app = createApp(App);

// Create a head instance for meta management
const head = createHead();

// Use router and head
app.use(router);
app.use(head);

app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
